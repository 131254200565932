import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import axios from "axios";
import { Routes } from "./router";
import { store } from "./store";

Vue.use(VueRouter);
const router = new VueRouter({
  routes: Routes,
  mode: "history",
});

Vue.prototype.$http = axios;
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "http://localhost:3000/";
} else {
  axios.defaults.baseURL = "https://api.amirmohammadgharibi.ir/";
}

// css
import "animate.css";
import "../public/styles.css";

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
