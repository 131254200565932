//- admin section -//
const register = (resolve) => {
  require.ensure(["./components/admin/Register.vue"], () => {
    resolve(require("./components/admin/Register.vue"));
  });
};

const login = (resolve) => {
  require.ensure(["./components/admin/Login.vue"], () => {
    resolve(require("./components/admin/Login.vue"));
  });
};

const homePanel = (resolve) => {
  require.ensure(["./components/admin/Home.vue"], () => {
    resolve(require("./components/admin/Home.vue"));
  });
};

const homePanelStart = (resolve) => {
  require.ensure(["./components/admin/HomeStart.vue"], () => {
    resolve(require("./components/admin/HomeStart.vue"));
  });
};

const projects = (resolve) => {
  require.ensure(["./components/admin/Projects.vue"], () => {
    resolve(require("./components/admin/Projects.vue"));
  });
};

const singleProject = (resolve) => {
  require.ensure(["./components/admin/SingleProject.vue"], () => {
    resolve(require("./components/admin/SingleProject.vue"));
  });
};

const createProject = (resolve) => {
  require.ensure(["./components/admin/CreateProject.vue"], () => {
    resolve(require("./components/admin/CreateProject.vue"));
  });
};

const personal = (resolve) => {
  require.ensure(["./components/admin/Personal.vue"], () => {
    resolve(require("./components/admin/Personal.vue"));
  });
};

const skills = (resolve) => {
  require.ensure(["./components/admin/Skills.vue"], () => {
    resolve(require("./components/admin/Skills.vue"));
  });
};

const createSkill = (resolve) => {
  require.ensure(["./components/admin/CreateSkill.vue"], () => {
    resolve(require("./components/admin/CreateSkill.vue"));
  });
};

const manageSkill = (resolve) => {
  require.ensure(["./components/admin/ManageSkill.vue"], () => {
    resolve(require("./components/admin/ManageSkill.vue"));
  });
};

const contacts = (resolve) => {
  require.ensure(["./components/admin/Contacts.vue"], () => {
    resolve(require("./components/admin/Contacts.vue"));
  });
};

//- website section -//

const homePage = (resolve) => {
  require.ensure(["./components/website/Home.vue"], () => {
    resolve(require("./components/website/Home.vue"));
  });
};

const project = (resolve) => {
  require.ensure(["./components/website/Project.vue"], () => {
    resolve(require("./components/website/Project.vue"));
  });
};

const showProjects = (resolve) => {
  require.ensure(["./components/website/ShowProjects.vue"], () => {
    resolve(require("./components/website/ShowProjects.vue"));
  });
};

const showSkills = (resolve) => {
  require.ensure(["./components/website/ShowSkills.vue"], () => {
    resolve(require("./components/website/ShowSkills.vue"));
  });
};

const showSkill = (resolve) => {
  require.ensure(["./components/website/SingleSkill.vue"], () => {
    resolve(require("./components/website/SingleSkill.vue"));
  });
};

const website = (resolve) => {
  require.ensure(["./components/website/Personal.vue"], () => {
    resolve(require("./components/website/Personal.vue"));
  });
};

//-

const notFound = (resolve) => {
  require.ensure(["./components/404.vue"], () => {
    resolve(require("./components/404.vue"));
  });
};

export const Routes = [
  {
    path: "/",
    component: homePage,
    // name: "home",
    children: [
      {
        path: "/",
        component: website,
        name: "home",
      },
      {
        path: "/projects",
        component: showProjects,
        name: "showProjects",
      },
      {
        path: "/project/:title",
        component: project,
        name: "project",
      },
      {
        path: "/skills",
        component: showSkills,
        name: "showSkills",
      },
      {
        path: "/skills/:title",
        component: showSkill,
        name: "showSkill",
      },
    ],
  },
  {
    path: "/register",
    component: register,
    name: "register",
  },
  {
    path: "/login",
    component: login,
    name: "login",
  },
  {
    path: "/panel",
    component: homePanel,
    // name: "homePanel",
    children: [
      {
        path: "",
        component: homePanelStart,
        name: "homePanel",
      },
      {
        path: "skills",
        component: skills,
        name: "skills",
      },
      {
        path: "skills/create",
        component: createSkill,
        name: "createSkill",
      },
      {
        path: "skills/manage/:title",
        component: manageSkill,
        name: "manageSkill",
      },
      {
        path: "contacts",
        component: contacts,
        name: "contacts",
      },
      {
        path: "projects",
        component: projects,
        name: "projects",
      },
      {
        path: "projects/single/:id",
        component: singleProject,
        name: "singleProject",
      },
      {
        path: "projects/create",
        component: createProject,
        name: "createProject",
      },
      {
        path: "personal",
        component: personal,
        name: "personal",
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    // component: notFound,
    redirect: "/",
  },
];
