import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  project: {},
  projects: [],
  contacts: [],
  skills: [],
  about: "",
});
