<template>
  <div id="app" class="w-100">
    <transition appear enter-active-class="animate__animated animate__fadeIn" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
